import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { TranslateLoader } from '@ngx-translate/core';

export class CachedHttpTranslationLoader implements TranslateLoader {

    cache$: Observable<object> = null;
    cachedLang: string = null;

    constructor(
        private http: HttpClient,
        public prefix: string = 'assets/i18n/',
        public suffix: string = '.json'
    ) {}

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<object> {
      if (!this.cache$ || this.cachedLang !== lang) {
        this.cache$ = this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(shareReplay(1));
        this.cachedLang = lang;
      }

      return this.cache$;
    }
}
