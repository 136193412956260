import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastrService: ToastrService
  ) { }

  displayInfoAlert(
    message: string,
    title = '',
    callbackFn: any = null,
    timeout = 2000,
    closeBtn = true,
    displayProgressBar = true,
    disableTimeout = true
  ) {
    if (callbackFn != null) {
      this.toastrService.info(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      }).onHidden
      .pipe(take(1))
      .subscribe(
        () => {
          callbackFn();
        }
      );
    } else {
      this.toastrService.info(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      });
    }
  }

  displaySuccessAlert(
    message: string,
    title = '',
    callbackFn: any = null,
    timeout = 2000,
    closeBtn = true,
    displayProgressBar = true,
    disableTimeout = true
  ) {

    if (callbackFn != null) {
      this.toastrService.success(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      }).onHidden
      .pipe(take(1))
      .subscribe(
        () => {
          callbackFn();
        }
      );
    } else {
      this.toastrService.success(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      });
    }

  }

  displayWarningAlert(
    message: string,
    title = '',
    callbackFn: any = null,
    timeout = 2000,
    closeBtn = true,
    displayProgressBar = true,
    disableTimeout = true
  ) {

    if (callbackFn != null) {
      this.toastrService.warning(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      }).onHidden
      .pipe(take(1))
      .subscribe(
        () => {
          callbackFn();
        }
      );
    } else {
      this.toastrService.warning(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      });
    }

  }

  displayErrorAlert(
    message: string,
    title = '',
    callbackFn: any = null,
    timeout = 2000,
    closeBtn = true,
    displayProgressBar = true,
    disableTimeout = true
  ) {

    if (callbackFn != null) {
      this.toastrService.error(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      }).onHidden
      .pipe(take(1))
      .subscribe(
        () => {
          console.log('callback invoked');
          callbackFn();
        }
      );
    } else {
      this.toastrService.error(message, title, {
        timeOut: timeout,
        disableTimeOut: disableTimeout,
        closeButton: closeBtn,
        progressBar: displayProgressBar,
        enableHtml: true
      });
    }

  }
}
