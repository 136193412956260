import { Permissions } from '@shared/enums/permissions.enum';

export class PermissionDetail {
    permissionCode: string;
    isAllocated: boolean;
    isActive: boolean;

    constructor(
        permissionCode = '',
        isAllocated = false
    ) {
        this.permissionCode = permissionCode;
        this.isAllocated = isAllocated;
        this.isActive = false;
    }

    toggleActivation() {
        /* if (this.permissionCode !== Permissions.VIEW) {
            this.isActive = !this.isActive;
        } */
        this.isActive = !this.isActive;
    }

    exportData() {
        return {};
    }
}
