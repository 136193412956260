import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@shared/services/authService/auth.service';
import { UtilityService } from '@shared/services/utilityService/utility.service';
import { StorageService } from '@shared/services/storageService/storage.service';
import { AlertService } from '@shared/services/alertService/alert.service';
import { SharedService } from '@shared/services/sharedService/shared.service';
import { UserPerimeter } from '@shared/models/user-perimeter.model';
import { ProfileDetail } from '@shared/models/profile-detail.model';
import { configuration } from '@shared/properties/configuration';

@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements OnInit, OnDestroy {

  private readonly onDestroy: Subject<any> = new Subject<any>();

  accessToken: any = undefined;

  userPerimeters: UserPerimeter[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private storageService: StorageService,
    private alertService: AlertService,
    private sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.accessToken = this.authService.getUserAccessToken();
    let userDetails;
    if (this.accessToken) {
      try {
        await this.authService.ensureIdTokenHasNotExpired();
        userDetails = await this.authService.getUserInfo();
      } catch (error) {
        this.accessToken = undefined;
      }

      if (userDetails && userDetails.email) {
        this.sharedService.fetchUserPerimeter()
        .pipe(
          takeUntil(this.onDestroy.asObservable())
        ).subscribe(
          respObj => {
            const respData = this.utilityService.convertObjectToJSON(respObj.body);

            if (respData.status === 'success') {
              const perimeters = respData.data.perimeter;

              if (perimeters.length > 0) {
                this.userPerimeters = [];

                perimeters.forEach(
                  perimeterObj => {
                    const tmpPerimeter = new UserPerimeter();

                    tmpPerimeter.importData(perimeterObj);

                    this.userPerimeters.push(tmpPerimeter);
                  }
                );

                if (this.userPerimeters.length === 1) {
                  const currentLanguage = this.userPerimeters[0].defaultLanguage.toLowerCase();

                  this.navigateToHome(this.userPerimeters[0], currentLanguage.toLowerCase());
                } else {
                  // @todo handle multi-client
                }
              } else {
                this.alertService.displayErrorAlert(
                  this.translateService.instant('commonText.msgNoPerimeterFound')
                );
              }
            }
          }
        );
      }
    }
  }

  login() {
    console.log('Login clicked...');
  }

  navigateToHome(currentPerimeter: UserPerimeter, currentLanguage: string) {
    this.storageService.storeObject('currentLanguage', currentLanguage, configuration.storageTypes.SessionStorage);

    if (this.translateService.currentLang !== currentLanguage) {
      this.translateService.use(currentLanguage);
    }

    this.storageService.storeObject('currentPerimeter', this.userPerimeters[0], configuration.storageTypes.SessionStorage);

    this.router.navigate([configuration.homeRoute]);

    this.sharedService.$loggedIn.next(true);
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }
}
