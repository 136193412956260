import { Injectable, ErrorHandler } from '@angular/core';

import { throwError } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: Error) {
    console.error('Intercepted Error: ', error);

    return throwError(error);
  }
}
