import { Component, OnInit } from '@angular/core';
import { AuthService } from '@shared/services/authService/auth.service';
import { User } from './../models/user.model';

@Component({
  selector: 'app-auth-profile',
  templateUrl: './auth-profile.component.html',
  styleUrls: ['./auth-profile.component.scss']
})
export class AuthProfileComponent implements OnInit {
  user: User;

  constructor(private authService: AuthService) { }

  async ngOnInit() {
    const authUserInfo = await this.authService.getUserInfo();
    this.user = User.fromAuthUserInfo(authUserInfo);
  }
}
