export enum FilterOperators {
    DEFAULT = '',
    EQUAL = 'eq',
    NOTEQUAL = 'neq',
    BETWEEN = 'bw',
    NOTBETWEEN = 'nbw',
    LESSTHAN = 'lt',
    LESSTHANEQUAL = 'lte',
    GREATERTHAN = 'gt',
    GREATERTHANEQUAL = 'gte',
    IN = 'in',
    NOTIN = 'nin'
}
