/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Injectable } from '@angular/core';
import { Permissions } from '@shared/enums/permissions.enum';
import { configuration } from '@shared/properties/configuration';
import { StorageService } from '../storageService/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private storageService: StorageService
  ) { }

  convertObjectToJSON(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  addEllipsis(originalStr = '', charCount = 50) {
    return originalStr && originalStr.length > charCount ? `${originalStr.substr(0, charCount)}...` : originalStr;
  }

  formatDateToString(date: Date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
  }

  validateAccess(moduleCode: string, featureCode: string, allowedLevels: string[] = [Permissions.CREATE, Permissions.MODIFY]) {
    const currentPerimeter = this.storageService.getObject('currentPerimeter', configuration.storageTypes.SessionStorage);

    if (currentPerimeter) {
      const hasAccess = currentPerimeter.associatedFunctions.some(moduleObj =>
        moduleObj.moduleCode === moduleCode && moduleObj.features.some(
          featureObj =>
          (featureCode === '*' || featureObj.featureCode === featureCode)
          && featureObj.permissions.some(
            permissionObj => permissionObj.isAllocated && (allowedLevels.indexOf('*') !== -1 || allowedLevels.indexOf(permissionObj.permissionCode) !== -1)
          )
        )
      );

      return hasAccess;
    }

    return false;
  }

  formatDate(date: Date) {
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
  }

}
