import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestrictInputDirective } from '@shared/directives/restrict-input.directive';
import { AuthorizationDirective } from '@shared/directives/authorization.directive';



@NgModule({
  declarations: [
    RestrictInputDirective,
    AuthorizationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RestrictInputDirective,
    AuthorizationDirective
  ]
})
export class SharedDirectivesModule { }
