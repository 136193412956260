export enum Permissions {
    VIEW = 'VIEW',
    CREATE = 'CREATE',
    MODIFY = 'MODIFY',
    DELETE = 'DELETE',
    REPORT = 'REPORT',
    IMPORT = 'IMPORT',
    VALIDATE = 'VALIDATE',
    ALL = '*'
}
