import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { configuration } from '@shared/properties/configuration';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private store: Map<string, any>;

  savedSearchSubject: Subject<any> = new Subject<any>();

  constructor() {
    this.store = new Map<string, any>();
  }

  storeObject(key: string, value: any, storageLocation: string = configuration.storageTypes.InMemory) {
    // eslint-disable-next-line eqeqeq
    if (key != undefined && key != null && key != '') {
      if (this.store.has(key)) {
        this.store.delete(key);
      }

      switch (storageLocation) {
        case configuration.storageTypes.InMemory:

          this.store.set(key, value);

          break;

        case configuration.storageTypes.LocalStorage:
          localStorage.setItem(key, JSON.stringify(value));

          break;

        case configuration.storageTypes.SessionStorage:
          sessionStorage.setItem(key, JSON.stringify(value));

          break;
      }
    }
  }

  removeObject(key: string, storageLocation: string = configuration.storageTypes.InMemory) {

    switch (storageLocation) {
      case configuration.storageTypes.InMemory:
          // eslint-disable-next-line eqeqeq
          if ((key != undefined && key != null && key != '') && this.store.has(key)) {
            this.store.delete(key);
          }

          break;

      case configuration.storageTypes.LocalStorage:
        // eslint-disable-next-line eqeqeq
        if ((key != undefined && key != null && key != '') && localStorage.getItem(key) !== null) {
          localStorage.removeItem(key);
        }

        break;

      case configuration.storageTypes.SessionStorage:
        // eslint-disable-next-line eqeqeq
        if ((key != undefined && key != null && key != '') && sessionStorage.getItem(key) !== null) {
          sessionStorage.removeItem(key);
        }

        break;
    }
  }

  getObject(key: string, storageLocation: string = configuration.storageTypes.InMemory) {

    switch (storageLocation) {
      case configuration.storageTypes.InMemory:
          // eslint-disable-next-line eqeqeq
          return ((key != undefined && key != null && key != '') && this.store.has(key)) ? this.store.get(key) : null;

      case configuration.storageTypes.LocalStorage:
        // eslint-disable-next-line eqeqeq
        return ((key != undefined && key != null && key != '') && localStorage.getItem(key) !== null) ? JSON.parse(localStorage.getItem(key)) : null;

      case configuration.storageTypes.SessionStorage:
        // eslint-disable-next-line eqeqeq
        return ((key != undefined && key != null && key != '') && sessionStorage.getItem(key) !== null) ? JSON.parse(sessionStorage.getItem(key)) : null;
    }

  }

  hasObject(key: string, storageLocation: string = configuration.storageTypes.InMemory) {
    switch (storageLocation) {
      case configuration.storageTypes.InMemory:
          // eslint-disable-next-line eqeqeq
          return ((key != undefined && key != null && key != '') && this.store.has(key));

      case configuration.storageTypes.LocalStorage:
        // eslint-disable-next-line eqeqeq
        return ((key != undefined && key != null && key != '') && localStorage.getItem(key) !== null);

      case configuration.storageTypes.SessionStorage:
        // eslint-disable-next-line eqeqeq
        return ((key != undefined && key != null && key != '') && sessionStorage.getItem(key) !== null);
    }
  }

  clearAllObjects() {
    this.store.clear();

    localStorage.clear();

    sessionStorage.clear();
  }
}
