<div class="container-fluid">
  <div class="row">
    <div class="col"></div>
    <div class="col-8 login-wrapper">
      <div class="login-content" *ngIf="accessToken; else loginDiv">
        <!-- Need to add code for multi-client here... -->
      </div>

      <ng-template #loginDiv>
        <div class="login-content">
          <button mat-raised-button class="login-btn"
            style="color: #fff;background-color: #336699;  border-radius: 3px;" routerLink="/auth/login">
            Login
          </button>
        </div>
      </ng-template>
    </div>
    <div class="col"></div>
  </div>
  <app-footer></app-footer>
</div>
