<ng-content select="app-header"></ng-content>
<div class="layout-section row no-gutters">
  <div class="col-3">
    <div class="home-icon m-0 pl-3 cursor-pointer" style="display:flex;">
      <div class="vertically-center-home-component" (click)="home()">
        <mat-icon style="height:28px; width:24px;" svgIcon="mmc-home">
        </mat-icon>
      </div>
    </div>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node example-tree-hover" (click)="handleClick(node.menuLink)">
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button></button>
          {{node.translateKey | translate}}
        </li>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li (click)="handleClick(node.path)" matTreeNodeToggle>
          <div class="mat-tree-node example-tree-hover">
            <button mat-icon-button matTreeNodeToggle>
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.translateKey | translate}}
          </div>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <div class="col-9">
    <router-outlet></router-outlet>
  </div>
</div>
