import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleModule } from '@ng-idle/core';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';

import { SharedCoreModule } from '@shared/modules/shared-core/shared-core.module';

import { CustomMatPaginatorIntl } from '@shared/modules/material/custom-mat-paginator-intl';

import { GlobalErrorHandler } from '@shared/interceptors/global-error.interceptor';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';

import { CachedHttpTranslationLoader } from '@shared/loaders/cached-http-translation-loader';

import { routes } from './app.routes';

import { DeleteConfirmDialogComponent } from '@shared/components/delete-confirm-dialog/delete-confirm-dialog.component';
import { SuccessfulDialogComponent } from '@shared/components/successful-dialog/successful-dialog.component';
import { CancelConfirmDialogComponent } from '@shared/components/cancel-confirm-dialog/cancel-confirm-dialog.component';
import { SessionTimeoutDialogComponent } from '@shared/components/session-timeout-dialog/session-timeout-dialog.component';
import { ClientSelectionComponent } from '@shared/components/client-selection/client-selection.component';
import { ModificationHistoryDialogComponent } from '@shared/components/modification-history-dialog/modification-history-dialog.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { LayoutComponent } from '@shared/components/layout/layout.component';

import { AppComponent } from './app.component';

import { environment } from '@environments/environment';

import { configuration } from '@shared/properties/configuration';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new CachedHttpTranslationLoader(http, 'assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#007CAA',
  bgsOpacity: 0.9,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.foldingCube, // background spinner type
  blur: 7,
  fgsType: SPINNER.threeStrings, // foreground spinner type
  fgsColor: '#007CAA',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 60,
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  logoSize: 50,
  logoUrl: '',
  hasProgressBar: true,
  pbColor: '#9cc1f1',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 3,
  text: '',
  textColor: '#FFFFFF',
  textPosition: POSITION.centerCenter,
  maxTime: -1,
  minTime: 300,
  gap: 50
};

@NgModule({
  declarations: [
    AppComponent,
    DeleteConfirmDialogComponent,
    SuccessfulDialogComponent,
    CancelConfirmDialogComponent,
    SessionTimeoutDialogComponent,
    ModificationHistoryDialogComponent,
    ClientSelectionComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    OktaAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: configuration.applicationLocale.backup
    }),
    NgIdleModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SharedCoreModule
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    {
      provide: OKTA_CONFIG,
      useValue: environment.okta
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeFr, 'fr');

    this.matIconRegistry.addSvgIcon('mmc-funnel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/funnel.svg')
    ).addSvgIcon(
      'mmc-search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg')
    ).addSvgIcon(
      'mmc-import',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/import.svg')
    ).addSvgIcon(
      'mmc-trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg')
    ).addSvgIcon(
      'mmc-select',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/select.svg')
    ).addSvgIcon(
      'mmc-checklist',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/checklist.svg')
    ).addSvgIcon(
      'mmc-history',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/history.svg')
    ).addSvgIcon(
      'mmc-equalizer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/equalizer.svg')
    ).addSvgIcon(
      'mmc-power-switch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/power-switch.svg')
    ).addSvgIcon(
      'mmc-question',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/question.svg')
    ).addSvgIcon(
      'mmc-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg')
    ).addSvgIcon(
      'mmc-home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg')
    ).addSvgIcon(
      'mmc-tab-checklist',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/checklist2.svg')
    ).addSvgIcon(
      'mmc-files',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/files.svg')
    ).addSvgIcon(
      'mmc-question-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/question-circle.svg')
    ).addSvgIcon(
      'mmc-check-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-circle.svg')
    ).addSvgIcon(
      'mmc-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus.svg')
    ).addSvgIcon(
      'mmc-business-card',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/business-card.svg')
    ).addSvgIcon(
      'mmc-chevron-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-right.svg')
    ).addSvgIcon(
      'mmc-chevron-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-left.svg')
    ).addSvgIcon(
      'mmc-upload2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload2.svg')
    ).addSvgIcon(
      'mmc-files-compare',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/files-compare.svg')
    ).addSvgIcon(
      'mmc-first',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/first.svg')
    ).addSvgIcon(
      'mmc-last',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/last.svg')
    ).addSvgIcon(
      'mmc-next',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/next-frame.svg')
    ).addSvgIcon(
      'mmc-previous',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/previous-frame.svg')
    ).addSvgIcon(
      'mmc-calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg')
    ).addSvgIcon(
      'mmc-calendar-cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar-cross.svg')
    ).addSvgIcon(
      'mmc-file-excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-excel.svg')
    ).addSvgIcon(
      'mmc-file-pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-pdf.svg')
    ).addSvgIcon(
      'mmc-file-powerpoint',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-powerpoint.svg')
    ).addSvgIcon(
      'mmc-file-word',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-word.svg')
    ).addSvgIcon(
      'mmc-sort-size-desc',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sort-size-desc.svg')
    ).addSvgIcon(
      'mmc-download-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/download-circle.svg')
    ).addSvgIcon(
      'mmc-refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/transfer-circle.svg')
    ).addSvgIcon(
      'mmc-file-download',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-download.svg')
    );
  }
}
