import { Group } from './group.model';

export class User {
  type: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  networkUsername: string;
  employeeNumber: string;
  groups: Group[];

  constructor(data) {
    this.type = data.type;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.displayName = data.displayName;
    this.email = data.email;
    this.networkUsername = data.networkUsername;
    this.employeeNumber = data.employeeNumber;
    this.groups = data.groups.map((group: any) => new Group(group));
  }

  static fromAuthUserInfo(data) {
    return new User({
      type: data.typ.toLowerCase(),
      firstName: data.given_name,
      lastName: data.family_name,
      displayName: data.name,
      email: data.email.toLowerCase(),
      networkUsername: data.nid,
      employeeNumber: User.extractEmployeeNumberFromOktaLgn(data.lgn),
      groups: data.groups.map((group: string) => ({
        name: group,
      })),
    });
  }

  private static extractEmployeeNumberFromOktaLgn(lgn) {
    if (lgn && lgn.includes('@')) {
      const split = lgn.split('@');
      if (split[0]) {
        return split[0];
      }
    }
    return null;
  }
}
