import { Injectable } from '@angular/core';

import { HttpHeaders } from '@angular/common/http';

import { StorageService } from '@shared/services/storageService/storage.service';

import { configuration } from '@shared/properties/configuration';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private storageService: StorageService
  ) { }

  fetchHeader(
    customHeaders: Map<string, string> = null
  ): HttpHeaders {
    let headers = new HttpHeaders({});

    /* const refreshToken = this.storageService.getObject('refresh_token', configuration.storageTypes.SessionStorage);
    const userDetails = this.storageService.getObject('userDetails', configuration.storageTypes.SessionStorage); */

    /* headers = headers.append('refresh_token', this.isNullOrEmpty(refreshToken) ? '' : refreshToken);
    headers = headers.append('username', this.isNullOrEmpty(userDetails) ? '' : userDetails.username); */
    headers = headers.append('Pragma', 'no-cache');

    if (customHeaders != null && customHeaders.size > 0) {
      customHeaders.forEach(
        (mapVal, mapKey) => {
          headers = headers.append(mapKey, mapVal);
        }
      );
    }

    return headers;
  }

  private isNullOrEmpty(obj: any) {
    // eslint-disable-next-line eqeqeq
    return obj == null || obj == undefined || obj == '';
  }
}
