import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { ReactiveFormConfig, ErrorMessageBindingStrategy } from '@rxweb/reactive-form-validators';

import { TranslateService } from '@ngx-translate/core';

import { StorageService } from '@shared/services/storageService/storage.service';

import { AuthHelper } from '@shared/helpers/auth-helper';

import { configuration } from '@shared/properties/configuration';

import { CONSTANTS } from '@shared/properties/constants';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends AuthHelper {
  title = 'planner-ui';
  isAuthenticated: boolean;
  readonly leftMainMenu: any[] = CONSTANTS.leftMainMenu;
  treeControl = new NestedTreeControl<any>(node => node.childItems);
  dataSource = new MatTreeNestedDataSource<any>();
  enableMain: boolean;
  mobileQuery: MediaQueryList;
  enablePages: boolean;

  currentLanguage = 'en';

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private storageService: StorageService
  ) {
    super();
    this.dataSource.data = this.leftMainMenu;

    ReactiveFormConfig.set({
      reactiveForm: {
        errorMessageBindingStrategy: ErrorMessageBindingStrategy.OnSubmit
      }
    });
  }

  home() {
    this.router.navigate([configuration.homeRoute]);
  }

  hasChild = (_: number, node) => !!node.childItems && node.childItems.length > 0;

  handleClick(path) {
    this.router.navigate([path]);
  }

  localeChanged(selectedVal: string) {
    if (this.currentLanguage !== selectedVal) {
      this.currentLanguage = selectedVal;

      this.storageService.storeObject('currentLanguage', this.currentLanguage, configuration.storageTypes.SessionStorage);

      this.translateService.use(this.currentLanguage);
    }
  }
}
