<p *ngIf="!user">Loading...</p>
<table *ngIf="user">
  <tr>
    <td>
      Type
    </td>
    <td>
      {{user.type}}
    </td>
  </tr>
  <tr>
    <td>
      First name
    </td>
    <td>
      {{user.firstName}}
    </td>
  </tr>
  <tr>
    <td>
      Last name
    </td>
    <td>
      {{user.lastName}}
    </td>
  </tr>
  <tr>
    <td>
      Display name
    </td>
    <td>
      {{user.displayName}}
    </td>
  </tr>
  <tr>
    <td>
      Email
    </td>
    <td>
      {{user.email}}
    </td>
  </tr>
  <tr>
    <td>
      Network username
    </td>
    <td>
      {{user. networkUsername}}
    </td>
  </tr>
  <tr>
    <td>
      Employee number
    </td>
    <td>
      {{user.employeeNumber}}
    </td>
  </tr>
  <tr>
    <td>
      Groups
    </td>
    <td>
      <span *ngFor="let group of user.groups">
        {{group.name}}<br>
      </span>
    </td>
  </tr>
</table>
