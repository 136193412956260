import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@shared/services/authService/auth.service';
import { StorageService } from '@shared/services/storageService/storage.service';
import { SharedService } from '@shared/services/sharedService/shared.service';
import { SessionService } from '@shared/services/sessionService/session.service';

import { SessionTimeoutDialogComponent } from '@shared/components/session-timeout-dialog/session-timeout-dialog.component';

import { AuthHelper } from '@shared/helpers/auth-helper';

import { configuration } from '@shared/properties/configuration';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AuthHelper implements OnInit {
  currentLanguage = 'fr';

  langArr: any[] = [];

  appVer = environment.appVersion;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private loaderService: NgxUiLoaderService,
    private translateService: TranslateService,
    private authService: AuthService,
    private sessionService: SessionService,
    private storageService: StorageService,
    private sharedService: SharedService
  ) {
    super();

    this.langArr = configuration.applicationLocale.supportedList.slice(0);

    this.sharedService.$loggedIn.subscribe(
      value => {
        if (value) {
          if (this.storageService.hasObject('currentLanguage', configuration.storageTypes.SessionStorage)) {
            this.currentLanguage = this.storageService.getObject('currentLanguage', configuration.storageTypes.SessionStorage);
          } else if (this.storageService.hasObject('currentPerimeter', configuration.storageTypes.SessionStorage)) {
            const currentPerimeter = this.storageService.getObject('currentPerimeter', configuration.storageTypes.SessionStorage);
            this.currentLanguage = currentPerimeter.defaultLanguage.toLowerCase();
          } else {
            this.currentLanguage = configuration.applicationLocale.default;
          }

          // the lang to use, if the lang isn't available, it will use the current loader to get them
          if (this.translateService.currentLang !== this.currentLanguage) {
            this.translateService.use(this.currentLanguage);
          }

          this.storageService.storeObject('currentLanguage', this.currentLanguage, configuration.storageTypes.SessionStorage);

          this.sessionService.initiateSession();

          this.sessionService.sessionDialog.subscribe(
            triggered => {
              if (triggered) {
                const dialogConfig = new MatDialogConfig();

                dialogConfig.panelClass = 'my-dialog';
                dialogConfig.disableClose = true;

                const dialogRef = this.dialog.open(SessionTimeoutDialogComponent, dialogConfig);

                dialogRef.afterClosed()
                .subscribe(
                  result => {
                    if (result) {
                      this.sessionService.startSession();
                    } else {
                      this.sessionService.discardSession();
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  ngOnInit() {
    if (this.storageService.hasObject('currentLanguage', configuration.storageTypes.SessionStorage)) {
      this.currentLanguage = this.storageService.getObject('currentLanguage', configuration.storageTypes.SessionStorage);
    } else if (this.storageService.hasObject('currentPerimeter', configuration.storageTypes.SessionStorage)) {
      const currentPerimeter = this.storageService.getObject('currentPerimeter', configuration.storageTypes.SessionStorage);
      this.currentLanguage = currentPerimeter.defaultLanguage.toLowerCase();
    } else {
      this.currentLanguage = configuration.applicationLocale.default;
    }

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (this.translateService.currentLang !== this.currentLanguage) {
      this.translateService.use(this.currentLanguage);
    }
  }

  hasUserDetails() {
    return this.storageService.hasObject('currentPerimeter', configuration.storageTypes.SessionStorage);
  }

  notReportURL() {
    // eslint-disable-next-line no-useless-escape
    const regexp = new RegExp('^\/reports\/.+$');
    return !regexp.test(this.router.url);
  }

  localeChanged(selectedVal: string) {
    if (this.currentLanguage !== selectedVal) {
      this.currentLanguage = selectedVal;

      this.storageService.storeObject('currentLanguage', this.currentLanguage, configuration.storageTypes.SessionStorage);

      this.translateService.use(this.currentLanguage);
    }
  }

  displayLoggedInUserName() {
    const currentUser = this.storageService.getObject('currentPerimeter', configuration.storageTypes.SessionStorage);

    return currentUser !== null && currentUser !== undefined ? currentUser.fullName : '';
  }

  logout() {
    const paginatorSize = this.storageService.getObject('paginatorSize', configuration.storageTypes.LocalStorage);
    this.loaderService.startBackgroundLoader('applicationLoader');
    this.authService.logout().then(() => {
      this.loaderService.stopBackgroundLoader('applicationLoader');
      this.storageService.clearAllObjects();
      this.storageService.storeObject('paginatorSize', paginatorSize, configuration.storageTypes.LocalStorage);
    });
  }

  fetchCurrentLanguage() {
    return (this.langArr.find(langObj => langObj.key === this.currentLanguage.toLowerCase())).value;
  }

}
