
import { ModuleDetail } from './module-detail.model';

import { PERMISSIONS } from '@shared/properties/permissions';

import { configuration } from '@shared/properties/configuration';

export class UserPerimeter {
    userId: number;
    firstName: string;
    lastName: string;
    fullName: string;
    defaultLanguage: string;
    emailAddress: string;

    profileId: number;

    associatedFunctions: ModuleDetail[];

    organizationalUnitId: number;
    organizationalUnitName: string;

    bcpLevelOrganizationalUnit: number;
    relocationFlag: number;
    relocationType: number;

    clientId: number;
    clientName: string;
    bcpLevel: number;

    localOULevel: number;

    constructor(
        userId = 0,
        firstName = '',
        lastName = '',
        defaultLanguage: string = configuration.applicationLocale.default,
        emailAddress = '',

        profileId = 0,

        organizationalUnitId = 0,
        organizationalUnitName = '',
        bcpLevelOrganizationalUnit = 0,
        relocationFlag = 0,
        relocationType = 0,

        clientId = 0,
        clientName = '',
        bcpLevel = 0,
        associatedFunctions: any[] = PERMISSIONS.permissionStructure
    ) {
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.defaultLanguage = defaultLanguage;
        this.emailAddress = emailAddress;

        this.profileId = profileId;

        this.organizationalUnitId = organizationalUnitId;
        this.organizationalUnitName = organizationalUnitName;
        this.bcpLevelOrganizationalUnit = bcpLevelOrganizationalUnit;
        this.relocationFlag = relocationFlag;
        this.relocationType = relocationType;

        this.clientId = clientId;
        this.clientName = clientName;
        this.bcpLevel = bcpLevel;

        this.associatedFunctions = [];

        if (associatedFunctions.length > 0) {
            associatedFunctions.forEach(
                functionObj => {
                    if (!functionObj.deprecated) {
                        this.associatedFunctions.push(new ModuleDetail(functionObj));
                    }
                }
            );
        }

        this.fullName = '';

        this.fullName += this.firstName;

        if (this.fullName !== '' && this.lastName !== '') {
            this.fullName += ` ${this.lastName}`;
        }
    }

    importData(perimeterObj: any) {
        this.userId = perimeterObj.id;
        this.firstName = perimeterObj.firstName;
        this.lastName = perimeterObj.lastName;

        this.fullName += this.firstName;

        if (this.fullName !== '' && this.lastName !== '') {
            this.fullName += ` ${this.lastName}`;
        }

        this.defaultLanguage = perimeterObj.language;
        this.emailAddress = perimeterObj.email;

        this.profileId = perimeterObj.profileId;

        this.organizationalUnitId = perimeterObj.organizationalUnitId;
        this.organizationalUnitName = perimeterObj.organizationalUnitName;
        this.bcpLevelOrganizationalUnit = perimeterObj.bcpLevelOrganizationalUnit;
        this.relocationFlag = perimeterObj.relocationFlag === undefined ? 0 : (perimeterObj.relocationFlag ? 1 : -1);
        this.relocationType = perimeterObj.relocationType === undefined ? 0 : (perimeterObj.relocationType ? 1 : -1);

        this.clientId = perimeterObj.clientId;
        this.clientName = perimeterObj.clientName;
        this.bcpLevel = perimeterObj.bcpLevel;
        this.localOULevel = perimeterObj.localOULevel;

        if (perimeterObj.permissions && perimeterObj.permissions.length > 0) {
            const allocatedModules = perimeterObj.permissions.map(permissionObj => permissionObj.split('.'));

            this.associatedFunctions.forEach(
                functionObj => {
                    functionObj.importPermissions(allocatedModules.filter(moduleObj => moduleObj[0] === functionObj.moduleCode));
                }
            );
        }
    }
}
