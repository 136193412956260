export const configuration = {
  partialURLs: {
    fetchUserPerimeter: '/perimeter',

    fetchCountries: '/countries?lang={0}',

    fetchOrganizationalUnit: '/organizational-units/{0}',
    saveOrganizationalUnit: '/organizational-units',
    saveChildOrganizationalUnit: '/organizational-units/{0}/organizational-units',
    deleteUpdateOrganizationalUnit: '/organizational-units/{0}',
    fetchOrganizationalUnitsLinkedToEvent: '/organizational-units?continuityPlanEventId={0}&linkType=secondary',
    moveOrganizationalUnit: '/organizational-units/{0}/move?organizationalUnitId={1}',
    importOrganizationalUnits: '/importing/organizational-unit-import',

    fetchContinuityPlan: '/continuity-plans?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    fetchAllContinuityPlan: '/continuity-plans?organizationalUnitId={0}&linkType={1}',
    getDeleteUpdateContinuityPlan: '/continuity-plans/{0}',
    saveContinuityPlan: '/continuity-plans?organizationalUnitId={0}',

    fetchContinuityPlanProcess: '/continuity-plans/{0}/processes?perPage={1}&currentPage={2}&sort[{3}]={4}{5}',
    fetchAllContinuityPlanProcess: '/continuity-plans/{0}/processes',
    deleteUpdateContinuityPlanProcess: '/continuity-plans/{0}/processes/{1}',
    validateContinuityPlanProcess: '/continuity-plans/{0}/processes/{1}/validate',
    saveContinuityPlanProcess: '/continuity-plans/{0}/processes',
    copyContinuityPlanProcess: '/continuity-plans/{0}/processes/{1}/copy',
    getContinuityPlanProcess: '/continuity-plans/{0}/processes/{1}',
    saveUpdateDeleteProcessActions: '/continuity-plans/{0}/processes/{1}/actions',

    fetchHumanResources: '/human-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateHumanResource: '/human-resources/{0}',
    checkOktaUser: '/human-resources/oktaEmail={0}',
    saveHumanResource: '/human-resources?organizationalUnitId={0}',
    moveHumanResource: '/human-resources/{0}/move?organizationalUnitId={1}',
    fetchResourceProcessTask: '/reporting/resource-process-task-details?',
    importHumanResources: '/importing/human-resource-import',
    validateWorksheet: '/importing/validate-worksheet',
    fetchAllHumanResource: '/human-resources?organizationalUnitId={0}&linkType={1}',
    fetchBusinessCorrespondents: '/reporting/business-correspondent-report?organizationalUnitId={0}',

    fetchAllGenericResource: '/generic-resources?organizationalUnitId={0}&linkType={1}',
    fetchGenericResources: '/generic-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateGenericResource: '/generic-resources/{0}',
    saveGenericResource: '/generic-resources?organizationalUnitId={0}',

    fetchAllSpecificEquipmentResource: '/equipment-resources?organizationalUnitId={0}&linkType={1}',
    fetchSpecificEquipmentResources: '/equipment-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateSpecificEquipmentResource: '/equipment-resources/{0}',
    saveSpecificEquipmentResource: '/equipment-resources?organizationalUnitId={0}',

    fetchAllPremises: '/premise-resources?organizationalUnitId={0}&linkType={1}',
    fetchPremise: '/premise-resources/{0}',
    fetchChildPremise: '/premise-resources/{0}/premise-recources',
    fetchPremiseResources: '/premise-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdatePremiseResource: '/premise-resources/{0}',
    savePremiseResource: '/premise-resources?organizationalUnitId={0}',
    saveOtherPremiseResource: '/premise-resources/{0}/premise-resources',
    fetchPremiseResourcesLinkedToEvent: '/premise-resources?continuityPlanEventId={0}&linkType=secondary',
    movePremiseResource: '/premise-resources/{0}/move?premiseResourceId={1}',
    importPremiseResources: '/importing/premise-resource-import',

    fetchAllServers: '/server-resources?organizationalUnitId={0}&linkType={1}',
    fetchServerResource: '/server-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateServerResource: '/server-resources/{0}',
    saveServerResource: '/server-resources?organizationalUnitId={0}',
    saveOtherServerResource: '/server-resources/{0}/server-resources',
    fetchServerResourcesLinkedToEvent: '/server-resources?continuityPlanEventId={0}&linkType=secondary',
    moveServerResource: '/server-resources/{0}/move?serverResourceId={1}',
    importServerResources: '/importing/server-resource-import',

    fetchAllITResource: '/application-resources?organizationalUnitId={0}&linkType={1}',
    fetchITResource: '/application-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateITResource: '/application-resources/{0}',
    saveParentITResource: '/application-resources?organizationalUnitId={0}',
    saveChildITResource: '/application-resources/{0}/application-resources',
    fetchApplicationResourcesLinkedToEvent: '/application-resources?continuityPlanEventId={0}&linkType=secondary',
    moveApplicationResource: '/application-resources/{0}/move?applicationResourceId={1}',
    importApplicationResources: '/importing/application-resource-import',

    fetchAllProviderResource: '/provider-resources?organizationalUnitId={0}&linkType={1}',
    fetchProviderResource: '/provider-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateProviderResource: '/provider-resources/{0}',
    saveProviderResource: '/provider-resources?organizationalUnitId={0}',
    fetchProviderResourcesLinkedToEvent: '/provider-resources?continuityPlanEventId={0}&linkType=secondary',
    importProviderResources: '/importing/provider-resource-import',

    fetchAllDocumentationResource: '/document-resources?organizationalUnitId={0}&linkType={1}',
    fetchDocumentationResource: '/document-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    deleteUpdateDocumentationResource: '/document-resources/{0}',
    saveDocumentationResource: '/document-resources?organizationalUnitId={0}',

    fetchOtherResource: '/other-resources?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    fetchAllOtherResource: '/other-resources?organizationalUnitId={0}&linkType={1}',
    deleteUpdateOtherResource: '/other-resources/{0}',
    saveOtherResource: '/other-resources?organizationalUnitId={0}',

    fetchWorkarounds: '/workaround-solutions?organizationalUnitId={0}&linkType={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    fetchAllWorkarounds: '/workaround-solutions?organizationalUnitId={0}&linkType={1}',
    deleteWorkaround: '/workaround-solutions/{0}',
    saveUpdateWorkaround: '/workaround-solutions?organizationalUnitId={0}',
    saveUpdateDeleteWorkaroundActions: '/workaround-solutions/{0}/actions',

    createDeleteResourceLinking: '/resource-links',
    deleteResourceLinking: '/resource-links/{0}',
    updateResourceLinking: '/resource-links/{0}',

    fetchAllLinkedPremises: '/premise-resources?organizationalUnitId={0}&linkType={1}&filter[isRecovery]={2}',
    fetchOULinkedPremises: '/premise-resources?organizationalUnitId={0}&linkType=secondary&perPage={1}&currentPage={2}&sort[{3}]={4}{5}&filter[isRecovery]={6}',
    savePremiseLoadups: '/premise-resources/{0}/loadups?organizationalUnitId={1}',
    fetchDeleteUpdateSpecificLoadup: '/premise-resources/{0}/loadups/{1}',
    fetchDeleteUpdatePremiseLoadups: '/premise-resources/{0}/loadups',

    fetchContinuityPlanTask: '/continuity-plans/{0}/tasks?continuityPlanProcessId={1}&perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    fetchAllContinuityPlanTask: '/continuity-plans/{0}/tasks',
    saveContinuityPlanTasks: '/continuity-plans/{0}/tasks?continuityPlanProcessId={1}',
    deleteUpdateContinuityPlanTasks: '/continuity-plans/{0}/tasks/{1}',
    validateContinuityPlanTasks: '/continuity-plans/{0}/tasks/{1}/validate',
    saveUpdateDeleteTaskActions: '/continuity-plans/{0}/tasks/{1}/actions',
    fetchContinuityPlanTaskFTEReport: '/reporting/continuity-plan-task-fte-report?{0}',

    fetchContinuityPlanEvents: '/continuity-plans/{0}/events?perPage={1}&currentPage={2}&sort[{3}]={4}{5}',
    deleteUpdateContinuityPlanEvent: '/continuity-plans/{0}/events/{1}',
    saveContinuityPlanEvent: '/continuity-plans/{0}/events',
    fetchAllContinuityPlanEvents: '/continuity-plans/{0}/events',
    eventResourceProcessReport: '/reporting/event-resource-process-report',

    createDeleteClientLinking: '/client-links',

    createDeleteContinuityPlanLinking: '/continuity-plan-links',
    deleteContinuityPlanLinking: '/continuity-plan-links/{0}',

    fetchOrganizationalUnitParentCandidates: '/reporting/organizational-unit-parent-candidates-report?organizationalUnitId={0}',
    fetchOrganizationalUnitBCPCandidates: '/reporting/organizational-unit-bcp-candidates-report',
    fetchOrganizationalUnitMemberCounts: '/reporting/organizational-unit-members-report?organizationalUnitId={0}',
    fetchOganizationalUnitHierarchy: '/reporting/organizational-unit-hierarchy-report?bcpLevel={0}',
    generateProcessSynthesisReport: '/reporting/process-synthesis-report?continuityPlanId={0}',
    fetchOrganizationalUnitCounts: '/reporting/organizational-unit-members-report?organizationalUnitId={0}',
    fetchDashboardFilterCandidates: '/reporting/organizational-unit-dashboard-filter-candidates-report?filterType={0}',
    fetchProcessTaskValidationStatusReport: '/reporting/process-task-validation-status-report?organizationalUnitId={0}&relocationType={1}',
    fetchInvalidationDeadlineStatusReport: '/reporting/invalidation-deadline-status-report?organizationalUnitId={0}&deadline={1}',
    fetchTestFollowupStatusReport: '/reporting/test-followup-status-report?organizationalUnitId={0}',

    fetchContinuityPlanTest: '/continuity-plans/{0}/events/{1}/tests?perPage={2}&currentPage={3}&sort[{4}]={5}{6}',
    fetchContinuityPlanTestLinkedToEvent: '/continuity-plans/{0}/tests?continuityPlanEventId={1}',
    saveContinuityPlanTest: '/continuity-plans/{0}/tests?continuityPlanEventId={1}',
    deleteUpdateContinuityPlanTest: '/continuity-plans/{0}/tests/{1}',
    fetchAllContinuityPlanTest: '/continuity-plans/{0}/tests?perPage={1}&currentPage={2}&sort[{3}]={4}{5}',
    fetchAllTesters: '/reporting/business-correspondent-report?organizationalUnitId={0}',

    fetchCustomAttributes: '/custom-attributes?organizationalUnitId={0}&filter[type]=in:{1}',

    fetchMacroProcesses: '/custom-attributes?organizationalUnitId={0}&filter[type]=macro-process',
    saveMacroProcess: '/custom-attributes?organizationalUnitId={0}',
    deleteUpdateMacroProcess: '/custom-attributes/{0}',

    fetchProcessAreas: '/custom-attributes?organizationalUnitId={0}&filter[type]=process-area',
    saveProcessArea: '/custom-attributes?organizationalUnitId={0}',
    deleteUpdateProcessArea: '/custom-attributes/{0}',

    fetchProcessCategories: '/custom-attributes?organizationalUnitId={0}&filter[type]=process-category',
    saveProcessCategory: '/custom-attributes?organizationalUnitId={0}',
    deleteUpdateProcessCategory: '/custom-attributes/{0}',

    fetchUsers: '/human-resources?linkType=primary&filter[profileId]=neq:null&perPage={0}&currentPage={1}&sort[{2}]={3}{4}',
    fetchBCPManagers: '/human-resources?organizationalUnitId={0}&linkType=primary&filter[profileBcpManager]=true',
    fetchBCPCorrespondents: '/human-resources?organizationalUnitId={0}&linkType=primary&filter[profileBcpCorrespondent]=true',

    getProfile: '/profiles/{0}',
    fetchSaveProfile: '/profiles',
    deleteUpdateProfile: '/profiles/{0}',

    getRoles: '/roles/{0}',
    fetchSaveRole: '/roles',
    deleteUpdateRole: '/roles/{0}',

    fetchSaveUpdateDeleteContinuityPlanTestResult: '/continuity-plans/{0}/tests/{1}/results',

    fetchHumanResourceListReport: '/reporting/human-resource-list-report?perPage={0}&currentPage={1}&sortOn={2}&sortBy={3}{4}',
    generateOUSynthesisReport: '/reporting/organizational-unit-synthesis-report?organizationalUnitId={0}',

    generateTreeViewOUReport: '/reporting/tree-view-organizational-unit-report?organizationalUnitId={0}',
    generateTreeViewProgressiveRelocationOUReport: '/reporting/tree-view-progressive-organizational-unit-report?organizationalUnitId={0}',

    generateEventReport: '/reporting/event-report?bcpId={0}',
    
    fetcProcessResourceListReport: '/reporting/process-resource-detail-report?perPage={1}&currentPage={2}&sortOn={3}&sortBy={4}{5}',

    fetchInvalidationConfigurations: '/invalidation-configurations?organizationalUnitId={0}',
    saveInvalidationConfiguration: '/invalidation-configurations?organizationalUnitId={0}',
    deleteUpdateInvalidationConfiguration: '/invalidation-configurations/{0}',

    fetchContinuityPlanListReport: '/reporting/continuity-plan-list-report?organizationalUnitId={0}',

    deleteFiles: '/files',
    fetchOrUploadFiles: '/files?{0}={1}',
    downloadFile: '/files/{0}/download',

    fetchFileLibraryListReport: '/reporting/file-library-list-report?organizationalUnitId={0}&perPage={1}&currentPage={2}&sort[{3}]={4}{5}',

    exportExcel: '/importing/import-outcome-report',

    fetchModificationHistory: '/modification-history?perPage={0}&currentPage={1}&sort[createdAt]=desc',

    fetchReportDownloads: '/report-downloads?sort[createdAt]=desc',
    downloadReport: '/report-downloads/{0}/download',
    genericResourceReport: '/reporting/resource-organizational-unit-report?organizationalUnitId={0}'    
  },

  callType: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
  },

  storageTypes: {
    InMemory: 'InMemory',
    LocalStorage: 'LocalStorage',
    SessionStorage: 'SessionStorage'
  },

  applicationLocale: {
    backup: 'fr',
    default: 'en',
    supportedList: [
      {
        key: 'en',
        value: 'English'
      },
      {
        key: 'fr',
        value: 'Français'
      }
    ]
  },

  homeRoute: 'dashboard',
  sessionTimeout: 20 // In minutes
};
