import { FeatureDetail } from './feature-detail.model';

export class ModuleDetail {
    moduleCode: string;
    translateKey: string;

    isExpanded: boolean;

    features: FeatureDetail[];

    constructor(
        moduleObject: any
    ) {
        this.moduleCode = moduleObject.moduleCode;
        this.translateKey = moduleObject.translateKey;

        this.isExpanded = false;

        this.features = [];

        if (moduleObject.features.length > 0) {
          moduleObject.features.forEach(
            featureObj => {
              if (!featureObj.deprecated) {
                this.features.push(new FeatureDetail(
                  featureObj.featureCode,
                  featureObj.translateKey,
                  featureObj.viewByDefault,
                  featureObj.permissions
                ));
              }
            });
        }
    }

    allocateAll() {
        this.features.forEach(
            featureObj => {
                featureObj.allocateAll();
            }
        );
    }

    allocateSelected() {
        this.features.forEach(
            featureObj => {
                featureObj.allocateSelected();
            }
        );
    }

    deallocateAll() {
        this.features.forEach(
            featureObj => {
                featureObj.deallocateAll();
            }
        );
    }

    deallocateSelected() {
        this.features.forEach(
            featureObj => {
                featureObj.deallocateSelected();
            }
        );
    }

    importPermissions(allocatedPermissions: any[] = []) {
        if (allocatedPermissions.length > 0) {
            this.features.forEach(
                featureObj => {
                    featureObj.importPermissions(allocatedPermissions.filter(permissionObj => permissionObj[1] === featureObj.featureCode));
                }
            );
        }
    }

    exportData() {
        return [].concat(...this.features.map(featureObj => featureObj.exportData(this.moduleCode)));
    }
}
