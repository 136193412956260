import { Permissions } from '@shared/enums/permissions.enum';

export const PERMISSIONS = {
    permissionStructure: [
        {
            moduleCode: 'reports',
            translateKey: 'permissionStructure.reports.title',
            features: [
                {
                    featureCode: 'dashboard',
                    translateKey: 'permissionStructure.reports.dashboard',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW
                    ]
                }
            ]
        },
        {
            moduleCode: 'homePage',
            translateKey: 'permissionStructure.homePage.title',
            features: [
                {
                    featureCode: 'documentaryBase',
                    translateKey: 'permissionStructure.homePage.documentaryBase',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'userGuide',
                    translateKey: 'permissionStructure.homePage.userGuide',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW
                    ]
                }
            ]
        },
        {
            moduleCode: 'organizationalUnit',
            translateKey: 'permissionStructure.organizationalUnit.title',
            features: [
                {
                    featureCode: 'generalInformation',
                    translateKey: 'permissionStructure.organizationalUnit.generalInformation',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE,
                        Permissions.IMPORT,
                        Permissions.REPORT
                    ]
                },
                {
                    featureCode: 'nominativePremises',
                    translateKey: 'permissionStructure.organizationalUnit.nominativePremises',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'relocationPremises',
                    translateKey: 'permissionStructure.organizationalUnit.relocationPremises',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'progressiveRelocation',
                    translateKey: 'permissionStructure.organizationalUnit.progressiveRelocation',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'workaroundSolution',
                    translateKey: 'permissionStructure.organizationalUnit.workaroundSolution',
                    deprecated: true,
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'library',
                    translateKey: 'permissionStructure.organizationalUnit.library',
                    permissions: [
                        Permissions.VIEW,
                        Permissions.DELETE
                    ]
                }
            ]
        },
        {
            moduleCode: 'humanResources',
            translateKey: 'permissionStructure.humanResources.title',
            features: [
                {
                    featureCode: 'humanResource',
                    translateKey: 'permissionStructure.humanResources.humanResource',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE,
                        Permissions.IMPORT,
                        Permissions.REPORT
                    ]
                },
                {
                    featureCode: 'humanResourceProcessTask',
                    translateKey: 'permissionStructure.humanResources.humanResourceProcessTask',
                    deprecated: true,
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                }
            ]
        },
        {
            moduleCode: 'otherResources',
            translateKey: 'permissionStructure.otherResources.title',
            features: [
                {
                    featureCode: 'otherResource',
                    translateKey: 'permissionStructure.otherResources.otherResource',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE,
                        Permissions.IMPORT,
                        Permissions.REPORT
                    ]
                }
            ]
        },
        {
            moduleCode: 'bcp',
            translateKey: 'permissionStructure.bcp.title',
            features: [
                {
                    featureCode: 'continuityPlan',
                    translateKey: 'permissionStructure.bcp.continuityPlan',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'continuityPlanProcess',
                    translateKey: 'permissionStructure.bcp.continuityPlanProcess',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE,
                        Permissions.REPORT,
                        Permissions.VALIDATE
                    ]
                },
                {
                    featureCode: 'continuityPlanTask',
                    translateKey: 'permissionStructure.bcp.continuityPlanTask',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE,
                        Permissions.VALIDATE
                    ]
                },
                {
                    featureCode: 'continuityPlanEvent',
                    translateKey: 'permissionStructure.bcp.continuityPlanEvent',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'continuityPlanTest',
                    translateKey: 'permissionStructure.bcp.continuityPlanTest',
                    viewByDefault: true,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                }
            ]
        },
        {
            moduleCode: 'administration',
            translateKey: 'permissionStructure.administration.title',
            features: [
                {
                    featureCode: 'macroProcess',
                    translateKey: 'permissionStructure.administration.macroProcess',
                    viewByDefault: false,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'processArea',
                    translateKey: 'permissionStructure.administration.processArea',
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'processCategory',
                    translateKey: 'permissionStructure.administration.processCategory',
                    viewByDefault: false,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'taskInvalidationDate',
                    translateKey: 'permissionStructure.administration.invalidationDate',
                    viewByDefault: false,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'natureDocuments',
                    translateKey: 'permissionStructure.administration.natureDocuments',
                    viewByDefault: false,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'profiles',
                    translateKey: 'permissionStructure.administration.profiles',
                    viewByDefault: false,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.CREATE,
                        Permissions.MODIFY,
                        Permissions.DELETE
                    ]
                },
                {
                    featureCode: 'users',
                    translateKey: 'permissionStructure.administration.users',
                    viewByDefault: false,
                    permissions: [
                        Permissions.VIEW,
                        Permissions.DELETE
                    ]
                }
            ]
        }
    ],
};
