import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EllipsisPipe } from '@shared/pipes/ellipsis.pipe';
import { FilterRecordsPipe } from '@shared/pipes/filter-records.pipe';

@NgModule({
  declarations: [
    EllipsisPipe,
    FilterRecordsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EllipsisPipe,
    FilterRecordsPipe
  ]
})
export class SharedPipesModule { }
