import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree, Router, NavigationCancel } from '@angular/router';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { StorageService } from '@shared/services/storageService/storage.service';
import { AlertService } from '@shared/services/alertService/alert.service';

import { configuration } from '@shared/properties/configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private alertService: AlertService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.storageService.hasObject('currentPerimeter', configuration.storageTypes.SessionStorage)) {
      return true;
    } else {
      this.alertService.displayErrorAlert(`Vous n'êtes pas connecté à Marsh BCP, veuillez vous connecter et entrer vos identifiants`);
      const paginatorSize = this.storageService.getObject('paginatorSize', configuration.storageTypes.LocalStorage);
      this.storageService.clearAllObjects();
      if (paginatorSize !== null) {
        this.storageService.storeObject('paginatorSize', paginatorSize, configuration.storageTypes.LocalStorage);
      }

      this.router.events
      .pipe(
        filter( event => event instanceof NavigationCancel)
      )
      .subscribe(
        (event) => {
          this.router.navigate(['client-selection']);
        }
      );

      return false;
    }
  }
}
