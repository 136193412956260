// import { version } from '../../package.json';

export const environment = {
  production: true,
  uiServerUrl: window.location.origin + '/api',
  resourceServerUrl: '${RESOURCE_SERVER_URL}',
  // appVersion: version,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('../../package.json').version,
  feature: {
    workFromHomeOption: '${FEATURE_WORK_FROM_HOME_OPTION}',
  },
  okta: {
    issuer: '${AUTHENTICATION_OKTA_ISSUER}',
    clientId: '${AUTHENTICATION_OKTA_CLIENT_ID}',
    redirectUri: window.location.origin + '/auth/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  }
};
