<ng-container *ngIf="notReportURL() && hasUserDetails()">
  <div class="header-bar">
    <mat-icon class="menu cursor-pointer" routerLink="/human-resources">menu</mat-icon>
    <img src="assets/logos/MarshBCP.png" class="vertically-center-bcp-logo img-fluid">
    <!-- <span style="color:white; font-size:30px;font-family: 'ArialMT', 'Arial';">MARSH BCP</span> -->
    <mat-icon class="menu right_icon cursor-pointer" svgIcon="mmc-power-switch"
      matTooltip="{{'applicationGenerals.logoutToolTip' | translate}}" (click)="logout()">
    </mat-icon>
    <mat-icon class="menu right_icon cursor-pointer" svgIcon="mmc-question"
      matTooltip="{{'applicationGenerals.userGuideToolTip' | translate}}" routerLink="/user-guide">
    </mat-icon>
    <mat-icon class="menu right_icon cursor-pointer" svgIcon="mmc-download-circle"
      matTooltip="{{'applicationGenerals.reportDownloadsToolTip' | translate}}" routerLink="/download-reports">
    </mat-icon>
    <mat-icon class="menu right_icon cursor-pointer" svgIcon="mmc-equalizer"
      matTooltip="{{'administrationMenu.menuTitle' | translate}}"
      *isAuthorized="[permissions.ALL];context:'administration.*'" routerLink="/administration"></mat-icon>

    <div class="right_icon locale-selector">

      <button mat-button [matMenuTriggerFor]="langOptions" class="btn-locale"
        matTooltip="{{'applicationGenerals.languageChangeTooltip' | translate}}">
        {{fetchCurrentLanguage()}}
        <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">
          arrow_drop_down
        </mat-icon>
      </button>
      <mat-menu #langOptions="matMenu" xPosition="after" yPosition="below">
        <button mat-menu-item *ngFor="let lang of langArr" (click)="localeChanged(lang.key)">{{lang.value}}</button>
      </mat-menu>
    </div>

    <div class="right_icon app-version">
      {{'Ver. ' + appVer}}
    </div>

    <div class="right_icon app-username">
      {{ displayLoggedInUserName() }}
    </div>
  </div>
</ng-container>
