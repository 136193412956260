import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { StorageService } from '@shared/services/storageService/storage.service';
import { AuthService } from '@shared/services/authService/auth.service';

import { configuration } from '@shared/properties/configuration';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private isIdle = false;

  private timedOut = false;

  sessionDialog: Subject<any> = new Subject<any>();

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private idle: Idle
  ) { }

  initiateSession() {
    this.idle.setIdle(configuration.sessionTimeout * 60);
    this.idle.setTimeout(1);

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.isIdle = false;
      console.log(`Idle End - ${(new Date()).toTimeString()}`);
    });

    this.idle.onTimeout.subscribe(() => {
      this.isIdle = true;
      this.timedOut = true;
      console.log(`Idle Timed Out - ${(new Date()).toTimeString()}`);

      this.sessionDialog.next(true);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.isIdle = true;
      console.log(`Idle Started - ${(new Date()).toTimeString()}`);
    });

    this.startSession();
  }

  startSession() {
    console.log(`Session Started / Restarted - ${(new Date()).toTimeString()}`);
    this.idle.watch();
    this.isIdle = false;
    this.timedOut = false;
  }

  discardSession() {
    const paginatorSize = this.storageService.getObject('paginatorSize', configuration.storageTypes.LocalStorage);
    this.authService.logout().then(() => {
      this.idle.stop();
      this.storageService.clearAllObjects();
      this.storageService.storeObject('paginatorSize', paginatorSize, configuration.storageTypes.LocalStorage);
    });
  }
}
