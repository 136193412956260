import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { UtilityService } from '@shared/services/utilityService/utility.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isAuthorized]'
})
export class AuthorizationDirective implements OnInit {

  private allowedLevels: string[];
  @Input() set isAuthorized(value: string[]) {
    this.allowedLevels = value;
  }

  private moduleCode = '';
  private featureCode = '';

  @Input() set isAuthorizedContext(value: string) {
    if (value.indexOf('.') !== -1) {
      const codes = value.split('.');

      if (codes.length === 2) {
        this.moduleCode = codes[0];
        this.featureCode = codes[1];
      }
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private utilityService: UtilityService
  ) {}

  validatePermissions() {
    return this.utilityService.validateAccess(this.moduleCode, this.featureCode, this.allowedLevels);
  }

  ngOnInit() {
    if (this.moduleCode !== '' && this.featureCode !== '' && this.validatePermissions()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
