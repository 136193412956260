import { Routes } from '@angular/router';

import { OktaAuthGuard, OktaCallbackComponent, OktaLoginRedirectComponent } from '@okta/okta-angular';

import { AuthProfileComponent } from '@shared/modules/auth/components/auth-profile.component';
import { ClientSelectionComponent } from '@shared/components/client-selection/client-selection.component';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { AuthGuard } from '@shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: 'auth/login',
    component: OktaLoginRedirectComponent
  },
  {
    path: 'auth/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'auth/profile',
    component: AuthProfileComponent
  },
  {
    path: 'client-selection',
    component: ClientSelectionComponent
  },
  {
    path: 'dashboard',
    canLoad: [AuthGuard],
    canActivate: [OktaAuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'reports',
    canLoad: [AuthGuard],
    canActivate: [OktaAuthGuard],
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: '',
    redirectTo: 'client-selection',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'general-information',
        redirectTo: 'organizational-unit/general-information'
      },
      {
        path: 'premise-linking',
        redirectTo: 'organizational-unit/premise-linking'
      },
      {
        path: 'library',
        redirectTo: 'organizational-unit/library'
      },
      {
        path: 'tasks',
        redirectTo: 'continuity-plan/tasks'
      },
      {
        path: 'processes',
        redirectTo: 'continuity-plan/processes'
      },
      {
        path: 'events',
        redirectTo: 'continuity-plan/events'
      },
      {
        path: 'tests',
        redirectTo: 'continuity-plan/tests'
      },
      {
        path: 'macro-process',
        redirectTo: 'administration/macro-process'
      },
      {
        path: 'process-area',
        redirectTo: 'administration/process-area'
      },
      {
        path: 'process-category',
        redirectTo: 'administration/process-category'
      },
      {
        path: 'profiles',
        redirectTo: 'administration/profiles'
      },
      {
        path: 'users',
        redirectTo: 'administration/users'
      },
      {
        path: 'invalidation-configuration',
        redirectTo: 'administration/invalidation-configuration'
      },
      {
        path: 'user-guide',
        redirectTo: 'administration/user-guide'
      },
      {
        path: 'download-reports',
        redirectTo: 'administration/download-reports'
      },
      {
        path: 'organizational-unit',
        canLoad: [AuthGuard],
        canActivate: [OktaAuthGuard],
        loadChildren: () => import('./modules/organizational-unit/organizational-unit.module').then(m => m.OrganizationalModule)
      },
      {
        path: 'human-resources',
        canLoad: [AuthGuard],
        canActivate: [OktaAuthGuard],
        loadChildren: () => import('./modules/human-resource/human-resource.module').then(m => m.HumanResourcesModule)
      },
      {
        path: 'continuity-plan',
        canLoad: [AuthGuard],
        canActivate: [OktaAuthGuard],
        loadChildren: () => import('./modules/continuity-plan/continuity-plan.module').then(m => m.ContinuityPlanModule)
      },
      {
        path: 'resource',
        canLoad: [AuthGuard],
        canActivate: [OktaAuthGuard],
        loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'administration',
        canLoad: [AuthGuard],
        canActivate: [OktaAuthGuard],
        loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule)
      }
    ]
  }
];
