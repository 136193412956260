import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

  itemsPerPageLabel = ' Éléments par page';
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';
  firstPageLabel = 'Première page';
  lastPageLabel = 'Dernière page';
  rangeSeparator = 'de';

  constructor(
    private translateService: TranslateService
  ) {
    super();

    this.translateLabels();

    this.translateService.onLangChange.subscribe(
        () => {
            this.translateLabels();

            this.changes.next();
        }
    );
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translateService.instant('applicationGenerals.customPaginator.itemsPerPage');
    this.nextPageLabel = this.translateService.instant('applicationGenerals.customPaginator.nextPage');
    this.previousPageLabel = this.translateService.instant('applicationGenerals.customPaginator.previousPage');
    this.firstPageLabel = this.translateService.instant('applicationGenerals.customPaginator.firstPage');
    this.lastPageLabel = this.translateService.instant('applicationGenerals.customPaginator.lastPage');
    this.rangeSeparator = this.translateService.instant('applicationGenerals.customPaginator.rangeSeparator');
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.rangeSeparator} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${(startIndex + 1)} - ${endIndex} ${this.rangeSeparator} ${length}`;
  }

}
