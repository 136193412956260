<div class="m-0 p-0">
  <div class="m-0 p-1" mat-dialog-title style="background-color: #336699; color:white;">
    <label>{{ 'commonText.sessionTimeoutDialog.title' | translate }}</label>
  </div>
  <div mat-dialog-content class="m-2 p-0 text-center">
    <div class="mt-2">
      {{'commonText.sessionTimeoutDialog.message' | translate}}
    </div>
  </div>
  <div mat-dialog-actions class="m-1 p-0" style="justify-content: center;">
    <button class="m-2 p-2" style="color: #fff;background-color: #336699;  border-radius: 3px;"
      (click)="dialogRef.close(true)"> {{ 'commonText.sessionTimeoutDialog.btnExtend' | translate }} </button>
    <button class="m-2 p-2" style="color: #336699;background-color: #fff;  border-radius: 3px;"
      (click)="dialogRef.close(false)">{{ 'commonText.sessionTimeoutDialog.btnLogout' | translate }}</button>
  </div>
</div>
