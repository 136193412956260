import { PermissionDetail } from './permission-detail.model';

import { Permissions } from '@shared/enums/permissions.enum';

export class FeatureDetail {
    featureCode: string;
    translateKey: string;
    viewByDefault: boolean;
    isExpanded: boolean;
    permissions: PermissionDetail[];

    constructor(
        featureCode = '',
        translateKey = '',
        viewByDefault = false,
        permissions: string[] = []
    ) {
        this.featureCode = featureCode;
        this.translateKey = translateKey;
        this.viewByDefault = viewByDefault;
        this.isExpanded = false;

        this.permissions = [];

        if (permissions.length > 0) {
            permissions.forEach(
                permissionObj => {
                    this.permissions.push(new PermissionDetail(
                        permissionObj,
                        (this.viewByDefault && permissionObj === Permissions.VIEW)
                    ));
                }
            );
        }
    }

    allocateAll() {
        this.permissions.forEach(
            permissionObj => {
                if (!permissionObj.isAllocated) {
                    permissionObj.isAllocated = true;
                    permissionObj.isActive = false;
                }
            }
        );
    }

    allocateSelected() {
        this.permissions.forEach(
            permissionObj => {
                if (!permissionObj.isAllocated && permissionObj.isActive) {
                    permissionObj.isAllocated = true;
                    permissionObj.isActive = false;
                }
            }
        );
    }

    deallocateAll() {
        this.permissions.forEach(
            permissionObj => {
                if ((!this.viewByDefault || permissionObj.permissionCode !== Permissions.VIEW) && permissionObj.isAllocated) {
                    permissionObj.isAllocated = false;
                    permissionObj.isActive = false;
                }
            }
        );
    }

    deallocateSelected() {
        this.permissions.forEach(
            permissionObj => {
                if ((!this.viewByDefault || permissionObj.permissionCode !== Permissions.VIEW) && permissionObj.isAllocated && permissionObj.isActive) {
                    permissionObj.isAllocated = false;
                    permissionObj.isActive = false;
                }
            }
        );
    }

    importPermissions(allocatedPermissions: any[] = []) {
        if (allocatedPermissions.length > 0) {
            this.permissions.forEach(
                permissionObj => {
                    if (allocatedPermissions.find(allocatedPermission => allocatedPermission[2] === permissionObj.permissionCode)) {
                        permissionObj.isAllocated = true;
                    }
                }
            );
        }
    }

    exportData(moduleCode: string) {
        return this.permissions.filter(permissionObj => permissionObj.isAllocated).map(permission => `${moduleCode}.${this.featureCode}.${permission.permissionCode}`);
    }
}
