import { FilterOperators } from '@shared/enums/filter-operators.enum';
import { LoaderTypes } from '@shared/enums/loader-types.enum';

import { configuration } from '@shared/properties/configuration';

export class HttpModel {
  partialURL: string;
  dataJSON: any;
  callType: string;
  nullifyBlanks: boolean;
  customHeaders: Map<string, string>;
  responseType: any;
  loaderDetails: any;

  /**
   * Constructor for HttpModel with the ability to default initialize the various attributes.
   * @param partURL Partial URL from the configuration file, which indicates the API endpoint that is to be trigerred
   * @param dJSON the data in JSON format that is to be sent to the API
   * @param callType The HTTP Method type that is to be used while invoking the API - `GET` | `POST` | `PATCH` | `DELETE`
   * @param loaderDetails identifier and type for the ngx loader that is to be shown or hidden
   * @param nullifyBlanks A boolean value indicating whether to send blank values as null to the  backend APIs
   * @param customHeaders A Map<string, string> to send any additional headers to the API
   * @param responseType If we want to override the response type of the API, defaults to `'JSON'`
   */
  constructor(
    partialURL = '',
    dJSON: any = {},
    callType: string = configuration.callType.GET,
    loaderDetails: any = {
      identifier: '',
      type: LoaderTypes.FOREGROUND
    },
    nullifyBlanks = true,
    customHeaders: Map<string, string> = null,
    responseType: any = 'json'
  ) {
    this.partialURL = partialURL;
    this.dataJSON = dJSON;
    this.callType = callType;
    this.nullifyBlanks = nullifyBlanks;
    this.responseType = responseType;
    this.loaderDetails = loaderDetails;

    this.customHeaders = customHeaders != null && customHeaders.size > 0 ? new Map<string, string>(customHeaders.entries()) : null;
  }

  populateURLParameters(...args: any[]) {
    this.partialURL = this.partialURL.replace(/{(\d+)}/g, (match, indicator) => {
      // eslint-disable-next-line eqeqeq
      return typeof args[indicator] != 'undefined' ? args[indicator] : match;
    });
  }

  addFilter(
    filterKey: string,
    filterValue: string | number | boolean,
    filterOperator: string = FilterOperators.DEFAULT
  ) {
    if (filterOperator === FilterOperators.DEFAULT) {
      this.partialURL = `${this.partialURL}&filter[${filterKey}]=${filterValue}`;
    } else {
      this.partialURL = `${this.partialURL}&filter[${filterKey}]=${filterOperator}:${filterValue}`;
    }
  }

  addFilters(
    ...args: {
      filterKey: string,
      filterValue: string | number | boolean,
      filterOperator: string
    }[] | {
      filterKey: string,
      filterValue: string | number | boolean
    }[]) {
    if (args.length > 0) {
      args.forEach(
        filterArg => {
          if (filterArg.filterOperator === FilterOperators.DEFAULT) {
            this.partialURL = `${this.partialURL}&filter[${filterArg.filterKey}]=${filterArg.filterValue}`;
          } else {
            this.partialURL = `${this.partialURL}&filter[${filterArg.filterKey}]=${filterArg.filterOperator}:${filterArg.filterValue}`;
          }
        }
      );
    }
  }

  addQueryParam(
    queryKey: string,
    queryValue: string | number | boolean,
    queryOperator: string = FilterOperators.DEFAULT
  ) {
    if (queryOperator === FilterOperators.DEFAULT) {
      this.partialURL = `${this.partialURL}&${queryKey}=${queryValue}`;
    } else {
      this.partialURL = `${this.partialURL}&${queryKey}=${queryOperator}:${queryValue}`;
    }
  }

  enableSorting(sortBy: string, sortDirection: string) {
    this.partialURL = `${this.partialURL}&sort[${sortBy}]=${sortDirection}`;
  }
}
