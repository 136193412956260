import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OktaAuthService } from '@okta/okta-angular';
import { ApiGatewayAccessToken } from '@shared/models/api-gateway-access-token.model';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticationState$: Observable<boolean>;
  private apiGatewayAccessToken: ApiGatewayAccessToken;

  constructor(
    private oktaAuthService: OktaAuthService,
    private http: HttpClient
  ) {
    this.authenticationState$ = oktaAuthService.$authenticationState;
  }

  isAuthenticated(): Promise<boolean> {
    return this.oktaAuthService.isAuthenticated();
  }

  getUserInfo() {
    return this.oktaAuthService.getUser();
  }

  getUserAccessToken(): string {
    return this.oktaAuthService.getAccessToken();
  }

  async ensureIdTokenHasNotExpired() {
    const idToken: any = await this.oktaAuthService.tokenManager.get('idToken');
    const validationOptions = {
      issuer: environment.okta.issuer
    }
    return this.oktaAuthService.token.verify(idToken, validationOptions);
  }

  async getApiGatewayAccessToken(): Promise<string> {
    if (!this.hasValidApiGatewayAccessToken()) {
      const userAccessToken = this.getUserAccessToken();
      this.apiGatewayAccessToken = await this.requestApiGatewayAccessToken(userAccessToken).toPromise();
    }

    return this.apiGatewayAccessToken.accessToken;
  }

  private hasValidApiGatewayAccessToken(): boolean {
    if (!this.apiGatewayAccessToken) { return false; }
    const expires = (this.apiGatewayAccessToken.issuedAt + this.apiGatewayAccessToken.expiresIn) - 10000; // 10 seconds before it expires
    return Date.now() < expires;
  }

  private requestApiGatewayAccessToken(userAccessToken: string): Observable<ApiGatewayAccessToken> {
    return this.http.post<ApiGatewayAccessToken>(environment.uiServerUrl + '/api-gateway-access-token', '', {
      headers: {
        Authorization: 'Bearer ' + userAccessToken,
      }
    }).pipe(
        map((data: any) => {
          return data.data.apiGatewayAccessToken;
        }),
    );
  }

  async logError(errorObj: HttpErrorResponse): Promise<void> {
    const userAccessToken = this.oktaAuthService.getAccessToken();
    const errorBody = {
      level: 'error',
      msg: `${errorObj.url} failed with error code: ${errorObj.status} having message: ${errorObj.message}`
    };
    await this.http.post<void>(environment.uiServerUrl + '/log', errorBody, {
      headers: {
        Authorization: 'Bearer ' + userAccessToken,
      }
    }).toPromise()
      .catch(err => console.error(err));
  }

  async logout(uri?: string): Promise<void> {
    const userAccessToken = this.oktaAuthService.getAccessToken();
    await this.http.post<void>(environment.uiServerUrl + '/logout', '', {
      headers: {
        Authorization: 'Bearer ' + userAccessToken,
      }
    }).toPromise()
      .catch(err => console.error(err));
    return this.oktaAuthService.signOut(uri);
  }
}
