import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { camelCase } from 'lodash-es';

import { HttpService } from '@shared/services/httpService/http.service';
import { UtilityService } from '../utilityService/utility.service';
import { StorageService } from '../storageService/storage.service';

import { HttpModel } from '@shared/models/http-detail';
import { CustomAttributeDetail } from '@shared/models/custom-attribute-detail.model';

import { FilterOperators } from '@shared/enums/filter-operators.enum';
import { LoaderTypes } from '@shared/enums/loader-types.enum';

import { configuration } from '@shared/properties/configuration';

import { CONSTANTS } from '@shared/properties/constants';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  $loggedIn: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private utilityService: UtilityService,
    private storageService: StorageService
  ) { }

  fetchAllHumanResources(
    organizationalId: number,
    hierarchicalFetch = false,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllHumanResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    if (hierarchicalFetch) {
      httpObj.addQueryParam('hierarchicalView', hierarchicalFetch);
    }

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllOtherResources(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllOtherResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllPremises(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
    sortBy = '',
    sortDirection = ''
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllPremises,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    if (sortBy !== '' && sortDirection !== '') {
      httpObj.enableSorting(sortBy, sortDirection);
    }

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllLinkedPremises(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
    isRecovery = false,
    sortBy = '',
    sortDirection = '',
    pageSize: number,
    pageIndex: number,
    filterString = ''
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllLinkedPremises,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    httpObj.populateURLParameters(organizationalId, linkType, isRecovery, pageIndex, pageSize);

    if (sortBy !== '' && sortDirection !== '') {
      httpObj.enableSorting(sortBy, sortDirection);
    }

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllServers(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
    serverIds: number[] = []
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllServers,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    if (serverIds.length > 0) {
      serverIds.forEach(
        id => {
          httpObj.addQueryParam('id', id);
        }
      );
    }

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllGenericResources(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllGenericResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllSpecificEquipmentResources(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllSpecificEquipmentResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllApplicationResources(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
    applicationIds: number[] = []
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllITResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    if (applicationIds.length > 0) {
      httpObj.addFilter(
        'id',
        applicationIds.reduce((finalVal, currentVal) => finalVal += finalVal === '' ? currentVal : `,${currentVal}`, ''),
        FilterOperators.IN
      );
    }

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllProviderResources(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllProviderResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllDocumentationResources(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllDocumentationResource,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  saveResourceLinking(resourcePremiseLinking) {
    const httpObj = new HttpModel(
      configuration.partialURLs.createDeleteResourceLinking,
      resourcePremiseLinking,
      configuration.callType.POST
    );

    httpObj.populateURLParameters();

    return this.httpService.invokeHttp(httpObj);
  }

  deleteResourceLinking(resourceId: number | any[]) {

    const httpObj = new HttpModel(
      typeof resourceId === 'number' ? configuration.partialURLs.deleteResourceLinking : configuration.partialURLs.createDeleteResourceLinking,
      typeof resourceId === 'number' ? null : resourceId,
      configuration.callType.DELETE
    );

    httpObj.populateURLParameters(resourceId);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllWorkarounds(
    organizationalId: number,
    linkType: string = CONSTANTS.linkTypes.PRIMARY,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllWorkarounds,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(organizationalId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllProcess(
    continuityPlanId: number,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllContinuityPlanProcess,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(continuityPlanId);
    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllTask(
    continuityPlanId: number,
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllContinuityPlanTask,
      null,
      configuration.callType.GET
    );

    httpObj.populateURLParameters(continuityPlanId);
    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllContinuityPlans(orgId: number, linkType: string) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchAllContinuityPlan,
      null,
      configuration.callType.GET
    );


    httpObj.populateURLParameters(orgId, linkType);

    return this.httpService.invokeHttp(httpObj);
  }

  /**
   * Will be moved to ContinuityPlanService post creation.
   */
  generateProcessSynthesisReport(
    reportName = '',
    processId = 0,
    planId = 0,
    processName = '',
    criticalProcessFilter = false,
    filterComparater: string,
    filterUnit: string,
    filterValue: number,
    macroProcessId = 0,
    processAreaId = 0,
    processCategoryId = 0,
    organizationalUnitId = -1,
    format = 'pdf'
    ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.generateProcessSynthesisReport,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    httpObj.populateURLParameters(planId);

    httpObj.addQueryParam('format', format);

    if (reportName !== '') {
      httpObj.addQueryParam('desiredReportName', reportName);
    }

    if (processId !== 0) {
      httpObj.addQueryParam('continuityPlanProcessId', processId);
    } else {
      if (processName) {
        httpObj.addFilter('searchName', processName.toLowerCase());
      }

      if (filterComparater && filterUnit && filterValue) {
        const value = filterValue * (filterUnit === 'Day' ? 86400 : 3600);
        httpObj.addFilter('recoveryTimeObjectiveRequested', value, filterComparater);
      }

      if (macroProcessId) {
        httpObj.addFilter('macroProcessId', macroProcessId);
      }

      if (processAreaId) {
        httpObj.addFilter('processAreaId', processAreaId);
      }

      if (processCategoryId) {
        httpObj.addFilter('processCategoryId', processCategoryId);
      }

      if (organizationalUnitId !== -1) {
        httpObj.addQueryParam('organizationalUnitId', organizationalUnitId);
      }

      if (criticalProcessFilter) {
        httpObj.addFilter('criticalProcess', criticalProcessFilter);
      }
    }

    return this.httpService.invokeHttp(httpObj);
  }

  generateOUSynthesisReport(organizationalId = 0) {
    const httpObj = new HttpModel(
      configuration.partialURLs.generateOUSynthesisReport,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    const custHeader = new Map<string, string>();

    custHeader.set('Accept', 'application/pdf');

    httpObj.responseType = 'blob' as 'json';
    httpObj.customHeaders = custHeader;

    httpObj.populateURLParameters(organizationalId);

    return this.httpService.invokeHttp(httpObj);
  }

  generateTreeViewOUReport(organizationalId = 0) {
    const httpObj = new HttpModel(
      configuration.partialURLs.generateTreeViewOUReport,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    const custHeader = new Map<string, string>();

    custHeader.set('Accept', 'application/xlsx');

    httpObj.responseType = 'blob' as 'json';
    httpObj.customHeaders = custHeader;

    httpObj.populateURLParameters(organizationalId);

    return this.httpService.invokeHttp(httpObj);
  }

  generateTreeViewProgressiveRelocationOUReport(organizationalId = 0) {
    const httpObj = new HttpModel(
      configuration.partialURLs.generateTreeViewProgressiveRelocationOUReport,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    const custHeader = new Map<string, string>();

    custHeader.set('Accept', 'application/xlsx');

    httpObj.responseType = 'blob' as 'json';
    httpObj.customHeaders = custHeader;

    httpObj.populateURLParameters(organizationalId);

    return this.httpService.invokeHttp(httpObj);
  }

  generateEventReport(bcpId = 0) {
    const httpObj = new HttpModel(
      configuration.partialURLs.generateEventReport,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    const custHeader = new Map<string, string>();

    custHeader.set('Accept', 'application/xlsx');

    httpObj.responseType = 'blob' as 'json';
    httpObj.customHeaders = custHeader;

    httpObj.populateURLParameters(bcpId);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchCustomAttributes(organizationalUnitId: number, typesArr: string[], language = '') {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchCustomAttributes,
      null,
      configuration.callType.GET,
    );

    httpObj.populateURLParameters(organizationalUnitId, typesArr.reduce((finalVal, currentVal) => finalVal += finalVal === '' ? currentVal : `,${currentVal}`, ''));

    return this.httpService.invokeHttp(httpObj)
      .pipe(
        map(
          result => {
            const respData = this.utilityService.convertObjectToJSON(result.body);

            if (respData.status === 'success') {
              if (!language) {
                language = this.storageService.hasObject('currentLanguage', configuration.storageTypes.SessionStorage) ?
                  this.storageService.getObject('currentLanguage', configuration.storageTypes.SessionStorage) : configuration.applicationLocale.default;
              }

              respData.data.customAttributes = respData.data.customAttributes.map(
                customAttribute => {
                  const tmpCustomAttribute = new CustomAttributeDetail();

                  tmpCustomAttribute.importData(customAttribute);

                  const exportedObj = tmpCustomAttribute.exportData();

                  return {
                    id: exportedObj.id,
                    type: exportedObj.type,
                    text: exportedObj.labels.find(label => label.language === language).text
                  };
                }
              );
            }

            return respData;
          }
        )
      );
  }

  fetchProfiles(language = '') {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchSaveProfile,
      null,
      configuration.callType.GET,
    );

    return this.httpService.invokeHttp(httpObj)
      .pipe(
        map(
          result => {
            const respData = this.utilityService.convertObjectToJSON(result.body);

            if (respData.status === 'success') {
              if (!language) {
                language = this.storageService.hasObject('currentLanguage', configuration.storageTypes.SessionStorage) ?
                  this.storageService.getObject('currentLanguage', configuration.storageTypes.SessionStorage) : configuration.applicationLocale.default;
              }

              respData.data.profiles = respData.data.profiles.map(
                profile => {
                  return {
                    id: profile.id,
                    text: profile.labels.find(label => label.language === language).text,
                    isBCPManager: profile.isBCPManager,
                    isBCPCorrespondent: profile.isBCPCorrespondent
                  };
                }
              );
            }

            return respData;
          }
        )
      );
  }

  fetchProfile(profileId: number) {
    const httpObj = new HttpModel(
      configuration.partialURLs.getProfile,
      null,
      configuration.callType.GET,
    );

    httpObj.populateURLParameters(profileId);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchBCPManagers(organizationalUnitId: number) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchBCPManagers,
      null,
      configuration.callType.GET,
    );

    httpObj.populateURLParameters(organizationalUnitId);

    return this.httpService.invokeHttp(httpObj)
      .pipe(
        map(
          result => {
            const respData = this.utilityService.convertObjectToJSON(result.body);

            if (respData.status === 'success') {
              respData.data.humanResources = respData.data.humanResources.map(
                humanResource => {
                  return {
                    id: humanResource.id,
                    firstName: humanResource.firstName,
                    lastName: humanResource.lastName
                  };
                }
              );
            }

            return respData;
          }
        )
      );
  }

  fetchBCPCorrespondents(organizationalUnitId: number, hierarchicalFetch = true) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchBusinessCorrespondents,
      null,
      configuration.callType.GET,
    );

    httpObj.populateURLParameters(organizationalUnitId);

    if (hierarchicalFetch) {
      httpObj.addQueryParam('hierarchicalView', hierarchicalFetch);
    }

    return this.httpService.invokeHttp(httpObj)
      .pipe(
        map(
          result => {
            const respData = this.utilityService.convertObjectToJSON(result.body);

            if (respData.status === 'success') {
              respData.data.businessCorrespondents = respData.data.businessCorrespondentReport.map(
                businessCorrespondent => {
                  return {
                    id: businessCorrespondent.id,
                    firstName: businessCorrespondent.firstName,
                    lastName: businessCorrespondent.lastName
                  };
                }
              );
            }

            return respData;
          }
        )
      );
  }

  fetchRoles(language = '') {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchSaveRole,
      null,
      configuration.callType.GET,
    );

    return this.httpService.invokeHttp(httpObj)
      .pipe(
        map(
          result => {
            const respData = this.utilityService.convertObjectToJSON(result.body);

            if (respData.status === 'success') {
              if (!language) {
                language = this.storageService.hasObject('currentLanguage', configuration.storageTypes.SessionStorage) ?
                  this.storageService.getObject('currentLanguage', configuration.storageTypes.SessionStorage) : configuration.applicationLocale.default;
              }

              respData.data.roles = respData.data.roles.map(
                role => {

                  return {
                    id: role.id,
                    text: role.labels.find(label => label.language === language).text
                  };
                }
              );
            }

            return respData;
          }
        )
      );
  }

  fetchUserPerimeter() {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchUserPerimeter,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    return this.httpService.invokeHttp(httpObj);
  }

  fetchAllContinuityPlansList(orgId: number) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchContinuityPlanListReport,
      null,
      configuration.callType.GET
    );


    httpObj.populateURLParameters(orgId);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchOUMemberCounts(orgUnitId: number) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchOrganizationalUnitMemberCounts,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    httpObj.populateURLParameters(orgUnitId);

    return this.httpService.invokeHttp(httpObj);
  }

  fetchModificationHistory(
    entityType: string,
    entityId: number,
    pageIndex = 1,
    pageSize = 10
  ) {
    const httpObj = new HttpModel(
      configuration.partialURLs.fetchModificationHistory,
      null,
      configuration.callType.GET,
      {
        identifier: 'applicationLoader',
        type: LoaderTypes.BACKGROUND
      }
    );

    httpObj.populateURLParameters(pageSize, pageIndex);

    httpObj.addQueryParam(`${camelCase(entityType)}Id`, entityId);

    return this.httpService.invokeHttp(httpObj);
  }
}
