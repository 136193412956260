import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { SecurityService } from '@shared/services/securityService/security.service';

import { HttpModel } from '@shared/models/http-detail';

import { LoaderTypes } from '@shared/enums/loader-types.enum';

import { configuration } from '@shared/properties/configuration';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private loaderQueue: Map<string, number> = new Map<string, number>();

  constructor(
    private loaderService: NgxUiLoaderService,
    private httpClient: HttpClient,
    private securityService: SecurityService
  ) { }

  /**
   * Starts or Stops the loader depending on the type, identifier and state provided
   * @param identifier Identifier of the loader
   * @param type Type of the loader `FOREGROUND` | `BACKGROUND` | `MASTER`
   * @param state State the loader should be in, `true` for start and `false` to stop
   */
  private toggleLoader(identifier: string, type: string, state = true) {
    switch (type) {
      case LoaderTypes.FOREGROUND:
        if (state) {
          this.loaderService.startLoader(identifier);
        } else {
          this.loaderService.stopLoader(identifier);
        }
        break;

      case LoaderTypes.BACKGROUND:
        if (state) {
          this.loaderService.startBackgroundLoader(identifier);
        } else {
          this.loaderService.stopBackgroundLoader(identifier);
        }
        break;

      case LoaderTypes.MASTER:
        if (state) {
          this.loaderService.start(identifier);
        } else {
          this.loaderService.stop(identifier);
        }
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  invokeHttp(httpObj: HttpModel): Observable<HttpResponse<Object>> {
    const serviceURL = `${environment.resourceServerUrl}${httpObj.partialURL}`;
    const httpHeaders = this.securityService.fetchHeader(httpObj.customHeaders);
    if (httpObj.nullifyBlanks) {
      const removeEmpty = obj => Array.isArray(obj) ? obj.map(item => removeEmpty(item)) :
        Object.keys(obj)
        .reduce(
          (newObj, k) =>
          (typeof obj[k] === 'object' && obj[k] !== null ? {
              ...newObj,
              [k]: ((typeof obj[k].getDate === 'function') || (Array.isArray(obj[k]) && typeof obj[k][0] !== 'object') ? obj[k] : 
                !Object.values(obj[k]).some(x => (x !== null && x !== '')) ? null : removeEmpty(obj[k]))
            } // Recurse.
            :
            {
              ...newObj,
              [k]: obj[k] === '' ? null : obj[k]
            }), // Copy value.
          {});

      if (httpObj.dataJSON && !(httpObj.dataJSON instanceof FormData)) {
        const convertedJSON = removeEmpty(httpObj.dataJSON);

        httpObj.dataJSON = convertedJSON;
      }
    }

    if (httpObj.loaderDetails.identifier !== '') {
      if (this.loaderQueue.has(httpObj.loaderDetails.identifier)) {
        const currentCount = this.loaderQueue.get(httpObj.loaderDetails.identifier);
        this.loaderQueue.set(httpObj.loaderDetails.identifier, (currentCount + 1));

        if (currentCount === 0) {
          this.toggleLoader(httpObj.loaderDetails.identifier, httpObj.loaderDetails.type);
        }
      } else {
        this.loaderQueue.set(httpObj.loaderDetails.identifier, 1);
        this.toggleLoader(httpObj.loaderDetails.identifier, httpObj.loaderDetails.type);
      }
    }

    switch (httpObj.callType) {
      case configuration.callType.GET:
        return this.httpClient.get(serviceURL, {
          headers: httpHeaders,
          observe: 'response',
          responseType: httpObj.responseType
        }).pipe(
          finalize(() => {
            if (httpObj.loaderDetails.identifier !== '') {
              if (this.loaderQueue.has(httpObj.loaderDetails.identifier)) {
                const currentCount = this.loaderQueue.get(httpObj.loaderDetails.identifier);

                this.loaderQueue.set(httpObj.loaderDetails.identifier, (currentCount - 1));

                if (currentCount === 1) {
                  this.toggleLoader(httpObj.loaderDetails.identifier, httpObj.loaderDetails.type, false);
                }
              }
            }
          })
        );

      case configuration.callType.POST:
        return this.httpClient.post(serviceURL, httpObj.dataJSON, {
          headers: httpHeaders,
          observe: 'response',
          responseType: httpObj.responseType
        }).pipe(
          finalize(() => {
            if (httpObj.loaderDetails.identifier !== '') {
              if (this.loaderQueue.has(httpObj.loaderDetails.identifier)) {
                const currentCount = this.loaderQueue.get(httpObj.loaderDetails.identifier);

                this.loaderQueue.set(httpObj.loaderDetails.identifier, (currentCount - 1));

                if (currentCount === 1) {
                  this.toggleLoader(httpObj.loaderDetails.identifier, httpObj.loaderDetails.type, false);
                }
              }
            }
          })
        );

      case configuration.callType.PATCH:
        return this.httpClient.patch(serviceURL, httpObj.dataJSON, {
          headers: httpHeaders,
          observe: 'response',
          responseType: httpObj.responseType
        }).pipe(
          finalize(() => {
            if (httpObj.loaderDetails.identifier !== '') {
              if (this.loaderQueue.has(httpObj.loaderDetails.identifier)) {
                const currentCount = this.loaderQueue.get(httpObj.loaderDetails.identifier);

                this.loaderQueue.set(httpObj.loaderDetails.identifier, (currentCount - 1));

                if (currentCount === 1) {
                  this.toggleLoader(httpObj.loaderDetails.identifier, httpObj.loaderDetails.type, false);
                }
              }
            }
          })
        );

      case configuration.callType.DELETE:
        return this.httpClient.request('delete', serviceURL, {
          headers: httpHeaders,
          body: httpObj.dataJSON,
          observe: 'response'
        }).pipe(
          finalize(() => {
            if (httpObj.loaderDetails.identifier !== '') {
              if (this.loaderQueue.has(httpObj.loaderDetails.identifier)) {
                const currentCount = this.loaderQueue.get(httpObj.loaderDetails.identifier);

                this.loaderQueue.set(httpObj.loaderDetails.identifier, (currentCount - 1));

                if (currentCount === 1) {
                  this.toggleLoader(httpObj.loaderDetails.identifier, httpObj.loaderDetails.type, false);
                }
              }
            }
          })
        );
    }
  }
}
