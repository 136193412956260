import { Component, OnInit } from '@angular/core';

import { CONSTANTS } from '@shared/properties/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  privacyTitle: string;
  termsTitle: string;
  cookieTitle: string;
  privacyUrl: string;
  termsUrl: string;
  cookieUrl: string;
  nameUrls: any;
  externalUrlTitle: any = CONSTANTS.privacyAndTerms;

  constructor() {
    this.initiateFooter();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // this.loadCookiesScript(CONSTANTS.privacyAndTerms.cookiesScript);
  }

  initiateFooter() {
    const languageCode = navigator.language.substring(0, 2);

    this.privacyTitle = this.externalUrlTitle.privacy.title[languageCode];
    this.privacyUrl = this.externalUrlTitle.privacy.url[languageCode];
    this.termsTitle = this.externalUrlTitle.terms.title[languageCode];
    this.termsUrl = this.externalUrlTitle.terms.url[languageCode];
    this.cookieTitle = this.externalUrlTitle.cookie.title[languageCode];
    this.cookieUrl = this.externalUrlTitle.cookie.url[languageCode];
  }

  public loadCookiesScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
}
