import { CONSTANTS } from '@shared/properties/constants';

export class CustomAttributeDetail {

    customAttributeId: number;

    attributeType: string;

    enLabel: string;

    frLabel: string;

    constructor(
        customAttributeId = 0,
        attributeType: string = CONSTANTS.customAttributeTypes.MACROPROCESS,
        enLabel = '',
        frLabel = ''
    ) {
        this.customAttributeId = customAttributeId;
        this.attributeType = attributeType;
        this.enLabel = enLabel;
        this.frLabel = frLabel;
    }

    absorbValues(customAttributeObj: CustomAttributeDetail) {
        this.customAttributeId = customAttributeObj.customAttributeId;
        this.attributeType = customAttributeObj.attributeType;
        this.enLabel = customAttributeObj.enLabel;
        this.frLabel = customAttributeObj.frLabel;
    }

    hasText(filterText: string) {
        if (filterText === '') {
            return true;
        } else if (filterText !== undefined && filterText !== '' && (this.enLabel.toLowerCase().includes(filterText.toLowerCase()) || this.frLabel.toLowerCase().includes(filterText.toLowerCase()))) {
            return true;
        } else {
            return false;
        }
    }

    importData(customAttributeObj: any) {
        this.customAttributeId = customAttributeObj.id;
        this.attributeType = customAttributeObj.type;

        if (customAttributeObj.labels) {
            customAttributeObj.labels.forEach(
                label => {
                    switch (label.language) {
                        case 'en':
                            this.enLabel = label.text;
                            break;

                        case 'fr':
                            this.frLabel = label.text;
                            break;
                    }
                }
            );
        }
    }

    exportData() {
        return {
            id: this.customAttributeId === 0 ? undefined : this.customAttributeId,
            type: this.attributeType,
            labels: [
                {
                    language: 'en',
                    text: this.enLabel
                },
                {
                    language: 'fr',
                    text: this.frLabel
                }
            ]
        };
    }
}
