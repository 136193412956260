<!-- Footer -->
<footer class="page-footer font-small login-footer">
  <!-- Footer Elements -->
  <div class="container">
    <div class="row txt-center py-3">
      <div class="col-md-4 txt-center">
        <a [href]="privacyUrl" class="waves-light" mdbWavesEffect>{{privacyTitle}}</a>
      </div>
      <div class="col-md-4 col-md-offset-2 text-center">
        <a [href]="termsUrl" class="waves-light" mdbWavesEffect>{{termsTitle}}</a>
      </div>
      <div class="col-md-4 col-md-offset-2 text-center">
        <a class="waves-light" mdbWavesEffect onclick="Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')">Manage Cookies</a>
      </div>
    </div>
    <div id="consent_blackbar"></div>
  </div>
</footer>
